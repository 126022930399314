.admin-layout {
    display: flex;
    min-height: 100vh;
    background-color: #f3f4f6;
  
    &__sidebar {
      width: 256px;
      background: white;
      border-right: 1px solid #e5e7eb;
      height: 100vh;
      position: fixed;
      transition: transform 0.2s ease-in-out;
      
      @media (max-width: 1024px) {
        transform: translateX(-100%);
        z-index: 50;
        
        &--open {
          transform: translateX(0);
        }
      }
    }
  
    &__sidebar-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid #e5e7eb;
    }
  
    &__title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  
    &__close-btn {
      display: none;
      color: #6b7280;
      
      @media (max-width: 1024px) {
        display: block;
      }
    }
  
    &__nav {
      flex: 1;
      padding: 1rem;
    }
  
    &__nav-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      padding: 0.75rem;
      border-radius: 0.5rem;
      margin-bottom: 0.5rem;
      color: #374151;
      transition: all 0.2s;
  
      &:hover {
        background-color: #f3f4f6;
      }
  
      &--active {
        background-color: #eff6ff;
        color: #2563eb;
      }
    }
  
    &__footer {
      padding: 1rem;
      border-top: 1px solid #e5e7eb;
    }
  
    &__logout-btn {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      padding: 0.75rem;
      border-radius: 0.5rem;
      color: #dc2626;
      transition: all 0.2s;
  
      &:hover {
        background-color: #fef2f2;
      }
    }
  
    &__content {
      flex: 1;
      margin-left: 256px;
      
      @media (max-width: 1024px) {
        margin-left: 0;
      }
    }
  
    &__mobile-header {
      display: none;
      padding: 0.75rem 1rem;
      background: white;
      border-bottom: 1px solid #e5e7eb;
      
      @media (max-width: 1024px) {
        display: block;
      }
    }
  
    &__menu-btn {
      color: #6b7280;
    }
  
    &__main {
      padding: 1.5rem;
    }
  }