// src/styles/admin.scss

// Variables
$primary-color: #3498db;
$secondary-color: #2ecc71;
$danger-color: #e74c3c;
$text-color: #2c3e50;
$light-gray: #ecf0f1;
$border-color: #bdc3c7;
$white: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.1);

$border-radius: 4px;
$spacing-unit: 8px;

// Login Page Styles
.admin-login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light-gray;

  &__container {
    width: 100%;
    max-width: 400px;
    padding: $spacing-unit * 4;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: 0 2px 10px $shadow-color;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: $spacing-unit * 3;
    color: $text-color;
  }

  &__error {
    background-color: rgba($danger-color, 0.1);
    border: 1px solid $danger-color;
    color: $danger-color;
    padding: $spacing-unit * 2;
    border-radius: $border-radius;
    margin-bottom: $spacing-unit * 2;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-unit * 2;
  }

  &__input-group {
    display: flex;
    flex-direction: column;
    gap: $spacing-unit;

    label {
      font-size: 14px;
      font-weight: 500;
      color: $text-color;
    }

    input {
      padding: $spacing-unit * 1.5;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      font-size: 16px;
      transition: border-color 0.2s;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }
  }

  &__button {
    padding: $spacing-unit * 1.5;
    background-color: $primary-color;
    color: $white;
    border: none;
    border-radius: $border-radius;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}

// Orders Page Styles
.admin-orders {
  min-height: 100vh;
  background-color: $light-gray;

  &__search {
    &-container {
      margin-bottom: 20px;
    }

    &-wrapper {
      position: relative;
      display: flex;
      align-items: center;
    }

    &-input {
      width: 100%;
      padding: 10px 15px;
      font-size: 1rem;
      border: 1px solid #e2e8f0;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease;

      &:focus {
        outline: none;
        border-color: #4299e1;
        box-shadow: 0 1px 3px rgba(66, 153, 225, 0.5);
      }
    }

    &-clear-btn {
      position: absolute;
      right: 10px;
      background: none;
      border: none;
      font-size: 16px;
      color: #718096;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;

      &:hover {
        color: #4a5568;
      }
    }

    &-results {
      margin-top: 8px;
      font-size: 0.9rem;
      color: #718096;
    }
  }


  &__navbar {
    background-color: $white;
    box-shadow: 0 2px 4px $shadow-color;
    padding: $spacing-unit * 2 0;

    &-content {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 $spacing-unit * 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      font-size: 20px;
      font-weight: bold;
      color: $text-color;
    }

    &-logout {
      padding: $spacing-unit * 1.5 $spacing-unit * 3;
      background-color: $danger-color;
      color: $white;
      border: none;
      border-radius: $border-radius;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: darken($danger-color, 10%);
      }
    }
  }

  &__content {
    max-width: 1200px;
    margin: 0 auto;
    padding: $spacing-unit * 3 $spacing-unit * 2;
  }

  &__card {
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: 0 2px 4px $shadow-color;
    padding: $spacing-unit * 3;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: $spacing-unit * 3;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    
    th, td {
      padding: $spacing-unit * 2;
      text-align: left;
      border-bottom: 1px solid $border-color;
    }

    th {
      font-weight: 600;
      color: $text-color;
      background-color: rgba($light-gray, 0.5);
    }

    tr:last-child td {
      border-bottom: none;
    }

    tr:hover {
      background-color: rgba($light-gray, 0.3);
    }
  }
  &__table-container {
    overflow-x: auto;
    margin-top: $spacing-unit * 2;
  }

  &__expand-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: $spacing-unit;
    color: $text-color;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__expanded-row {
    background-color: rgba($light-gray, 0.3);
  }

  &__expanded-content {
    padding: $spacing-unit * 2;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 600;
    color: $text-color;
    margin: $spacing-unit * 2 0 $spacing-unit;

    &:first-child {
      margin-top: 0;
    }
  }

  &__products-table {
    width: 100%;
    margin-top: $spacing-unit * 2;
    border-collapse: collapse;
    
    th, td {
      padding: $spacing-unit * 1.5;
      text-align: left;
      border: 1px solid $border-color;
    }

    th {
      background-color: rgba($light-gray, 0.5);
      font-weight: 600;
      color: $text-color;
    }

    tr:hover {
      background-color: rgba($light-gray, 0.2);
    }
  }

  &__payment-mode {
    display: inline-block;
    padding: $spacing-unit $spacing-unit * 2;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    background-color: rgba($primary-color, 0.1);
    color: $primary-color;
  }

  &__payment-status {
    display: inline-block;
    padding: $spacing-unit $spacing-unit * 2;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    background-color: rgba($primary-color, 0.1);
    color: $primary-color;

    &--pending {
      background-color: rgba(#f1c40f, 0.1);
      color: #f1c40f;
    }

    &--paid {
      background-color: rgba($secondary-color, 0.1);
      color: $secondary-color;
    }

    &--failed {
      background-color: rgba($danger-color, 0.1);
      color: $danger-color;
    }
  }

  // Improve responsiveness
  @media (max-width: 768px) {
    &__expanded-content {
      padding: $spacing-unit;
    }

    &__products-table {
      th, td {
        padding: $spacing-unit;
        font-size: 14px;
      }
    }
  }


  &__status {
    display: inline-block;
    padding: $spacing-unit $spacing-unit * 2;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;

    &--delivered {
      background-color: rgba($secondary-color, 0.1);
      color: $secondary-color;
    }

    &--processing {
      background-color: rgba($primary-color, 0.1);
      color: $primary-color;
    }

    &--shipped {
      background-color: rgba(#f1c40f, 0.1);
      color: #f1c40f;
    }
  }
}



// Responsive Styles
@media (max-width: 768px) {
  .admin-orders {
    &__content {
      padding: $spacing-unit * 2 $spacing-unit;
    }

    &__table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      
      th, td {
        padding: $spacing-unit * 1.5;
      }
    }
  }
}