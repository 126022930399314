// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Font Variable
$font-bodoni: 'Libre Bodoni', serif;

// Breakpoints
$mobile: 640px;
$tablet: 1024px;
$desktop: 1440px;

.single-banner-container {
  width: 100vw !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
  font-family: $font-bodoni;
  box-sizing: border-box;
  
  * {
    box-sizing: border-box;
  }

  .banner-wrapper {
    width: 100vw !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
    
    &:hover {
      box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
    }

    .banner-content {
      position: relative;
      width: 100% !important;
      overflow: hidden;
      
      video {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
        object-position: center !important;
        transition: opacity 0.3s ease;
        will-change: opacity;
      }
    }
  }
}

// Shimmer animation for loading state
@keyframes shimmerEffect {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.loader-container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}