@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

.footer {
  position: relative;
  background-color: #066469;
  color: #e5e5e5;
  font-family: 'Poppins', sans-serif;
  padding: 4rem 0 2rem;
  margin-top: 100px; // Space for the waves

  // Waves container and SVG
  &__waves-container {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    overflow: hidden;
  }

  &__waves {
    position: absolute;
    width: 100%;
    height: 100px;
    min-width: 1000px;
  }

  &__waves {
    position: absolute;
    width: 100%;
    height: 100px;
    min-width: 1000px;
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-transform: translateZ(0); /* Safari fix */
    perspective: 1000px;
  }

  &__wave-parallax {
    /* Hardware acceleration tricks */
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    
    .wave {
      /* Critical: these properties ensure animations never stop */
      animation-iteration-count: infinite !important;
      animation-play-state: running !important;
      animation-fill-mode: forwards !important;
      will-change: transform;
      transform: translate3d(0, 0, 0);
      
      /* Force GPU rendering */
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
    }
    
    .wave-1 {
      animation: move-forever-1 7s cubic-bezier(.55,.5,.45,.5) infinite;
    }
    
    .wave-2 {
      animation: move-forever-2 10s cubic-bezier(.55,.5,.45,.5) infinite;
    }
    
    .wave-3 {
      animation: move-forever-3 13s cubic-bezier(.55,.5,.45,.5) infinite;
    }
    
    .wave-4 {
      animation: move-forever-4 20s cubic-bezier(.55,.5,.45,.5) infinite;
    }
  }

  // Floating elements
  &__floating-elements {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__floating-element {
    position: absolute;
    pointer-events: none;
  }

  &__leaf {
    top: 20px;
    left: 10%;
    width: 30px;
    height: 30px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23e5e5e5' opacity='0.6'%3E%3Cpath d='M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10c2.5 0 4.9-1 6.7-2.7 3.1-3.1 3.1-8.2 0-11.3C17.1 6.3 14.6 5 12 5c-2.8 0-5 2.2-5 5s2.2 5 5 5c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3H7c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5c-2.8 0-5-2.2-5-5H2c0 5.5 4.5 10 10 10s10-4.5 10-10S17.5 2 12 2z'/%3E%3C/svg%3E") no-repeat center;
    animation: float 10s ease-in-out infinite, sway 8s ease-in-out infinite;
  }

  &__bubble {
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    
    &--small {
      width: 15px;
      height: 15px;
      top: 30px;
      left: 30%;
      animation: float 8s ease-in-out infinite, sway 5s ease-in-out infinite;
    }
    
    &--medium {
      width: 25px;
      height: 25px;
      top: 20px;
      left: 60%;
      animation: float 12s ease-in-out infinite, sway 6s ease-in-out infinite;
    }
    
    &--large {
      width: 35px;
      height: 35px;
      top: 10px;
      left: 80%;
      animation: float 15s ease-in-out infinite, sway 7s ease-in-out infinite;
    }
  }

  &__lotus {
    top: 15px;
    left: 50%;
    width: 40px;
    height: 40px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23e5e5e5' opacity='0.7'%3E%3Cpath d='M12 2C7.03 2 3 6.03 3 11c0 4.97 4.03 9 9 9s9-4.03 9-9c0-4.97-4.03-9-9-9zm0 16c-3.86 0-7-3.14-7-7 0-3.86 3.14-7 7-7s7 3.14 7 7c0 3.86-3.14 7-7 7z'/%3E%3Ccircle cx='12' cy='11' r='3'/%3E%3C/svg%3E") no-repeat center;
    animation: float 20s ease-in-out infinite, sway 10s ease-in-out infinite;
  }

  &__container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    z-index: 2;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__logo {
    height: 48px;
    margin-bottom: 1.5rem;
    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
  }

  &__newsletter-text {
    margin-bottom: 1.5rem;
    font-size: 0.95rem;
    line-height: 1.5;
  }

  &__form {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(229, 229, 229, 0.3), rgba(229, 229, 229, 0.8), rgba(229, 229, 229, 0.3));
    }
  }

  &__input {
    flex: 1;
    background: transparent;
    border: none;
    padding: 0.75rem 0;
    color: #e5e5e5;
    font-size: 0.95rem;
    font-family: 'Poppins', sans-serif;

    &::placeholder {
      color: rgba(229, 229, 229, 0.7);
    }

    &:focus {
      outline: none;
    }
  }

  &__submit {
    background: transparent;
    border: none;
    color: #e5e5e5;
    padding: 0.5rem;
    cursor: pointer;
    margin-left: 0.5rem;
    transition: transform 0.3s ease;

    i {
      font-size: 1.1rem;
    }

    &:hover {
      color: #fff;
      transform: translateY(-3px);
    }
  }

  &__subscribe-button {
    width: 100%;
    padding: 0.85rem;
    margin: 1rem 0 1.5rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(229, 229, 229, 0.3);
    color: #e5e5e5;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.95rem;
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
      transition: all 0.6s ease;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      border-color: rgba(229, 229, 229, 0.5);
      color: #fff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      
      &::before {
        left: 100%;
      }
    }

    &:focus {
      outline: none;
      border-color: rgba(229, 229, 229, 0.7);
    }
  }

  &__error {
    color: #ff6b6b;
    font-size: 0.85rem;
    margin: 0.5rem 0;
    text-align: left;
  }

  &__success {
    color: #51cf66;
    font-size: 0.85rem;
    margin: 0.5rem 0;
    text-align: left;
  }

  &__social {
    display: flex;
    gap: 1.2rem;
  }

  &__social-link {
    color: #e5e5e5;
    text-decoration: none;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);

    i {
      font-size: 1rem;
    }

    &:hover {
      color: #fff;
      background: rgba(255, 255, 255, 0.2);
      transform: translateY(-3px);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }
  }

  &__heading {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    position: relative;
    padding-bottom: 0.75rem;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 2px;
      background: rgba(229, 229, 229, 0.5);
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 0.85rem;
      position: relative;
      padding-left: 1.2rem;
      
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: rgba(229, 229, 229, 0.5);
      }

      a {
        color: #e5e5e5;
        text-decoration: none;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
        
        &::after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 0;
          height: 1px;
          background: #fff;
          transition: width 0.3s ease;
        }

        &:hover {
          color: #fff;
          
          &::after {
            width: 100%;
          }
        }
      }
    }
  }

  &__time {
    margin-top: 1.5rem !important;
    font-style: italic;
    opacity: 0.8;
  }

  &__address {
    margin-top: 1.8rem;
    font-size: 0.9rem;
    line-height: 1.6;
    position: relative;
    padding-left: 1.5rem;
    
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.3rem;
      width: 1px;
      height: calc(100% - 0.3rem);
      background: rgba(229, 229, 229, 0.3);
    }

    p {
      margin-bottom: 0.35rem;
    }
  }

  &__address-heading {
    font-weight: 500;
    margin-bottom: 0.75rem !important;
    color: rgba(255, 255, 255, 0.9);
  }
  
  &__copyright {
    text-align: center;
    margin-top: 3rem;
    padding-top: 1.5rem;
    font-size: 0.9rem;
    opacity: 0.8;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      height: 1px;
      background: linear-gradient(90deg, rgba(229, 229, 229, 0), rgba(229, 229, 229, 0.5), rgba(229, 229, 229, 0));
    }
  }
}

/* Separate animations for each wave to prevent synchronization issues */
@keyframes move-forever-1 {
  0% { transform: translate3d(-90px, 0, 0); }
  100% { transform: translate3d(85px, 0, 0); }
}

@keyframes move-forever-2 {
  0% { transform: translate3d(-90px, 0, 0); }
  100% { transform: translate3d(85px, 0, 0); }
}

@keyframes move-forever-3 {
  0% { transform: translate3d(-90px, 0, 0); }
  100% { transform: translate3d(85px, 0, 0); }
}

@keyframes move-forever-4 {
  0% { transform: translate3d(-90px, 0, 0); }
  100% { transform: translate3d(85px, 0, 0); }
}

/* Critical CSS to ensure animations NEVER stop */
.footer__waves-container {
  /* Hardware acceleration for the container */
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  will-change: transform;
}

.wave {
  /* These important flags override any browser attempt to pause animations */
  animation-play-state: running !important;
}

/* Highest priority override for all browsers */
@media all {
  .wave {
    animation-play-state: running !important;
  }
}

/* Special fix for Safari */
@supports (-webkit-overflow-scrolling: touch) {
  .footer__wave-parallax .wave {
    /* Safari-specific fixes */
    -webkit-animation-play-state: running !important;
    -webkit-animation-duration: inherit !important;
    -webkit-animation-delay: inherit !important;
    -webkit-animation-iteration-count: infinite !important;
    -webkit-animation-fill-mode: forwards !important;
  }
}

/* Fix for Firefox */
@-moz-document url-prefix() {
  .footer__wave-parallax .wave {
    animation-play-state: running !important;
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes sway {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
}

// Media Queries
@media (max-width: 768px) {
  .footer {
    padding-top: 3rem;
    
    &__waves {
      height: 80px;
    }
    
    &__waves-container {
      height: 80px;
      top: -80px;
    }
  }
}

@media (max-width: 576px) {
  .footer {
    margin-top: 60px;
    
    &__waves {
      height: 60px;
    }
    
    &__waves-container {
      height: 60px;
      top: -60px;
    }
    
    &__floating-elements {
      display: none;
    }
  }
}