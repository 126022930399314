// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

// Font Variables
$font-bodoni: 'Libre Bodoni', serif;
$font-montserrat: 'Montserrat', sans-serif;

// Colors
$primary-color: #066469;
$secondary-color: #6cd4d9;
$accent-color: #f8d968;
$text-dark: #2d3748;
$text-light: #4a5568;
$background-gradient: linear-gradient(135deg, #f0fafb 0%, #cff1f3 100%);

.product-showcase {
  display: flex;
  background: $background-gradient;
  font-family: $font-bodoni;
  position: relative;
  overflow: hidden;
  max-width: 1600px;
  margin: 3rem auto;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
  min-height: 600px;
  
  &.animate-in {
    .product-info > * {
      animation: fadeInUp 0.5s ease-out forwards;
    }
  }
  
  .background-pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      radial-gradient(circle at 10% 20%, rgba(108, 212, 217, 0.1) 0%, transparent 50%),
      radial-gradient(circle at 90% 80%, rgba(108, 212, 217, 0.1) 0%, transparent 50%);
    z-index: 0;
  }
  
  .content-wrapper {
    flex: 1;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  .product-info {
    max-width: 550px;
    
    > * {
      opacity: 0;
      transform: translateY(20px);
      animation-fill-mode: forwards;
      
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: #{0.15 * $i}s;
        }
      }
    }
  }
  
  .brand-badge {
    display: inline-block;
    background-color: $accent-color;
    color: $primary-color;
    font-family: $font-montserrat;
    font-weight: 600;
    padding: 0.25rem 1rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
    letter-spacing: 1px;
    
    span {
      font-size: 0.8rem;
    }
  }

  .title {
    font-size: 2.5rem;
    color: $primary-color;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 60px;
      height: 3px;
      background-color: $secondary-color;
    }
  }

  .category {
    display: flex;
    align-items: center;
    color: $secondary-color;
    font-weight: 600;
    margin: 1rem 0;
    font-family: $font-montserrat;
    letter-spacing: 1px;

    .home-icon {
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
      background: currentColor;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'/%3E%3Cpolyline points='9 22 9 12 15 12 15 22'/%3E%3C/svg%3E");
      mask-repeat: no-repeat;
      mask-position: center;
    }
  }

  .product-name {
    font-size: 3.5rem;
    color: $text-dark;
    margin-bottom: 1.5rem;
    line-height: 1.1;
    font-weight: 700;
  }

  .description {
    color: $text-light;
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    font-family: $font-montserrat;
    letter-spacing: 0.5px;
    font-weight: 500;
  }
  
  .highlights {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .product-highlight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    .highlight-number {
      font-size: 2.5rem;
      font-weight: 700;
      color: $primary-color;
      line-height: 1;
    }
    
    .highlight-text {
      font-size: 0.85rem;
      font-weight: 600;
      font-family: $font-montserrat;
      color: $text-light;
      letter-spacing: 0.5px;
      max-width: 120px;
    }
  }

  .features {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    max-width: 500px;
  }

  .feature-container {
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
    }
  }

  .feature-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;

    .icon-wrapper {
      background-color: $secondary-color;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;
      transition: all 0.3s ease;
      position: relative;
      
      &:hover {
        transform: scale(1.1);
        background-color: $primary-color;
      }
      
      &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        border-radius: 50%;
        border: 2px solid transparent;
        transition: all 0.3s ease;
      }
      
      &:hover::before {
        border-color: $secondary-color;
        transform: scale(1.05);
      }

      &::after {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        background-color: white;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
      }

      &.factory-icon::after {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M2 20a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8l-7 5V8l-7 5V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z'/%3E%3Cpath d='M17 18h1'/%3E%3Cpath d='M12 18h1'/%3E%3Cpath d='M7 18h1'/%3E%3C/svg%3E");
      }
      
      &.droplet-icon::after {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z'/%3E%3C/svg%3E");
      }

      &.leaf-icon::after {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M11 20A7 7 0 0 1 9.8 6.1C15.5 5 17 4.48 19 2c1 2 2 4.18 2 8 0 5.5-4.78 10-10 10Z'/%3E%3Cpath d='M2 21c0-3 1.85-5.36 5.08-6C9.5 14.52 12 13 13 12'/%3E%3C/svg%3E");
      }

      &.clock-icon::after {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'/%3E%3Cpolyline points='12 6 12 12 16 14'/%3E%3C/svg%3E");
      }
      
      &.color-icon::after {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='13.5' cy='6.5' r='2.5'/%3E%3Ccircle cx='19' cy='12' r='2.5'/%3E%3Ccircle cx='16.5' cy='19' r='2.5'/%3E%3Ccircle cx='8.5' cy='19' r='2.5'/%3E%3Ccircle cx='5.5' cy='12' r='2.5'/%3E%3Ccircle cx='8.5' cy='6.5' r='2.5'/%3E%3C/svg%3E");
      }
    }

    span {
      font-size: 0.75rem;
      font-weight: 600;
      color: $text-dark;
      font-family: $font-montserrat;
      max-width: 80px;
      letter-spacing: 0.5px;
    }
  }
  
  .feature-description {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    color: $text-dark;
    font-family: $font-montserrat;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    max-width: 450px;
    border-left: 3px solid $primary-color;
    animation: fadeIn 0.3s ease-out;
  }
  
  .cta-container {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .shop-now {
    background-color: $primary-color;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: $font-montserrat;
    letter-spacing: 1px;
    box-shadow: 0 4px 10px rgba(6, 100, 105, 0.3);

    &:hover {
      background-color: darken($primary-color, 5%);
      transform: translateY(-3px);
      box-shadow: 0 6px 15px rgba(6, 100, 105, 0.4);
    }
    
    &:active {
      transform: translateY(-1px);
    }
  }
  
  .price {
    display: flex;
    flex-direction: column;
    
    .old-price {
      text-decoration: line-through;
      color: $text-light;
      font-size: 1rem;
    }
    
    .current-price {
      color: $primary-color;
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  .image-wrapper {
    flex: 0 0 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 2rem;
    position: relative;
    overflow: hidden;
    border-radius: 0 16px 16px 0;
    z-index: 1;
  }
  
  .image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 90%;
    perspective: 1000px;
    transform-style: preserve-3d;
    z-index: 2;
  }

  .product-image {
    max-width: 85%;
    height: auto;
    object-fit: contain;
    filter: drop-shadow(0 15px 25px rgba(0, 0, 0, 0.15));
    transition: transform 0.5s ease;
    transform-origin: center center;
    z-index: 2;
  }

  .glow-effect {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140%;
    height: 140%;
    background: radial-gradient(circle, rgba(108, 212, 217, 0.3) 0%, rgba(108, 212, 217, 0) 70%);
    filter: blur(30px);
    animation: pulse 4s ease-in-out infinite;
    z-index: 1;
  }
  
  .product-badge {
    position: absolute;
    top: 40px;
    right: 40px;
    background-color: $accent-color;
    color: $primary-color;
    font-weight: 700;
    padding: 0.7rem;
    font-size: 0.9rem;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(15deg);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 3;
    animation: pulse 2s ease-in-out infinite;
  }
  
  .formulation-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
  }

  .formulation-line {
    position: absolute;
    top: 40%;
    left: 40%;
    display: flex;
    align-items: center;
    animation: fadeIn 0.5s ease-in-out forwards;
    
    &::before {
      content: '';
      width: 50px;
      height: 1px;
      background-color: $primary-color;
      margin-right: 10px;
    }
    
    .formulation-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $primary-color;
      margin-right: 10px;
      animation: pulse 2s infinite;
    }
    
    .formulation-label {
      color: $primary-color;
      font-weight: 600;
      font-size: 0.8rem;
      font-family: $font-montserrat;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 0.3rem 0.6rem;
      border-radius: 4px;
    }
  }

  .bubbles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  .bubble {
    position: absolute;
    background: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.4));
    border-radius: 50%;
    bottom: -60px;
    animation-name: float-bubble;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.8), 0 0 10px rgba(108, 212, 217, 0.3);

    &.popped {
      animation: pop 0.5s forwards;
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
      opacity: 0.8;
    }
    50% {
      transform: scale(1.05);
      opacity: 1;
    }
  }

  @keyframes float-bubble {
    0% {
      transform: translateY(0) translateX(0);
      opacity: 0;
    }
    10% {
      opacity: 0.8;
    }
    100% {
      transform: translateY(-100vh) translateX(calc(20px * var(--random-x, 1)));
      opacity: 0;
    }
  }

  @keyframes pop {
    0% {
      transform: scale(1);
      opacity: 0.8;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.5;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    
    .content-wrapper {
      padding: 3rem;
    }
    
    .image-wrapper {
      min-height: 500px;
      border-radius: 0 0 16px 16px;
    }
    
    .product-info {
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin: 1.5rem;

    .content-wrapper {
      padding: 2rem 1.5rem;
    }

    .image-wrapper {
      padding: 1.5rem;
      min-height: 400px;
      border-radius: 16px 16px 0 0;
    }

    .product-image {
      max-width: 75%;
    }

    .product-name {
      font-size: 2.5rem;
    }

    .features {
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }
    
    .feature-icon .icon-wrapper {
      width: 50px;
      height: 50px;
    }
    
    .product-badge {
      top: 20px;
      right: 20px;
      width: 50px;
      height: 50px;
      font-size: 0.8rem;
    }
    
    .highlights {
      flex-direction: column;
      gap: 1rem;
    }
    
    .cta-container {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
    
    .shop-now {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    .title {
      font-size: 2rem;
    }
    
    .product-name {
      font-size: 2rem;
    }
    
    .description {
      font-size: 1rem;
    }
    
    .formulation-info {
      display: none;
    }
  }
}