// Variables
$primary-color: #2563eb;
$primary-hover: #1d4ed8;
$text-color: #111827;
$text-gray: #4b5563;
$white: #ffffff;
$border-color: #e5e7eb;
$shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$container-width: 72rem;

// Mixins
@mixin card {
  background-color: $white;
  border-radius: 0.5rem;
  box-shadow: $shadow;
  padding: 2rem;
}

// Main Container
.contact-container {
  max-width: $container-width;
  margin: 0 auto;
  padding: 3rem 1rem;
}

// Hero Section
.hero-section {
  text-align: center;
  margin-bottom: 4rem;

  h1 {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: $text-color;
  }

  p {
    font-size: 1.125rem;
    color: $text-gray;
    max-width: 42rem;
    margin: 0 auto;
  }
}

// Contact Cards
.contact-cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 4rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.contact-card {
  @include card;
  text-align: center;

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 auto 1rem;
    color: $primary-color;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: $text-color;
  }

  p {
    color: $text-gray;
    margin-bottom: 0.5rem;
  }

  .subtitle {
    font-size: 0.875rem;
    color: $text-gray;
  }
}

// Form Section
.form-section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: 3fr 2fr;
  }
}

.form-container {
  @include card;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: $text-color;
  }
}

.form-group {
  margin-bottom: 1.5rem;

  label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: $text-color;
  }

  input, textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid $border-color;
    border-radius: 0.375rem;
    transition: border-color 0.2s ease;

    &:focus {
      outline: none;
      border-color: $primary-color;
    }
  }
}

.submit-button {
  width: 100%;
  background-color: $primary-color;
  color: $white;
  padding: 0.75rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: $primary-hover;
  }
}

// Add these to your existing Contact.scss file

.alert {
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  text-align: center;
  
  &.error-alert {
    background-color: #fef2f2;
    border: 1px solid #fee2e2;
    color: #dc2626;
  }
  
  &.success-alert {
    background-color: #f0fdf4;
    border: 1px solid #dcfce7;
    color: #16a34a;
  }
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem 1.5rem;
  background-color: #066469;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: darken(#066469, 10%);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.loading {
    background-color: darken(#066469, 5%);
  }

  .spinner {
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-group {
  input, textarea {
    &:disabled {
      background-color: #f3f4f6;
      cursor: not-allowed;
    }
  }
}

// Map Container
.map-container {
  @include card;
}

.map-placeholder {
  background-color: #f3f4f6;
  border-radius: 0.375rem;
  height: 100%;
  min-height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .map-overlay {
    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: $text-color;
    }

    p {
      color: $text-gray;
    }
  }
}