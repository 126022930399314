// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

// Enhanced Color Palette
$primary-color: #066469;
$primary-light: #0aa4aa;
$primary-dark: #044447;
$secondary-color: #328387;
$accent-color: #F8E16C;
$accent-secondary: #FFB347; // Added warm orange accent
$background-color: #ffffff;
$text-color: #333333;
$light-gray: #f8f8f8;
$medium-gray: #e0e0e0;
$dark-gray: #666666;
$hover-color: #e5f6f7;
$shadow-color: rgba(84, 197, 203, 0.1);
$overlay-color: rgba(0, 0, 0, 0.5);
$error-color: #d32f2f;
$success-color: #2e7d32;

// Font Variables
$font-bodoni: 'Libre Bodoni', serif;
$font-sans: 'Montserrat', sans-serif;

// Transitions & Animations
$transition-quick: 0.2s;
$transition-duration: 0.3s;
$transition-slow: 0.5s;
$transition-ease: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$transition-bounce: cubic-bezier(0.34, 1.56, 0.64, 1);

// Breakpoints
$small-mobile: 480px;
$mobile: 768px;
$tablet: 1024px;
$desktop: 1200px;

@mixin small-mobile {
  @media (max-width: #{$small-mobile - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile}) and (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

// Enhanced Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin glass-effect {
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@mixin card-shadow {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

@mixin hover-lift {
  transition: transform $transition-duration $transition-bounce, box-shadow $transition-duration $transition-ease;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 28px rgba(6, 100, 105, 0.15);
  }
}

@mixin subtle-border {
  border: 1px solid rgba(6, 100, 105, 0.08);
  border-radius: 12px;
}

// Enhanced Animations
@keyframes rotateIn {
  from {
    transform: rotate(-180deg) scale(0.8);
    opacity: 0;
  }
  to {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

@keyframes rotateOut {
  from {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
  to {
    transform: rotate(180deg) scale(0.8);
    opacity: 0;
  }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; visibility: hidden; }
}

@keyframes zoomIn {
  from { transform: scale(0.5); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes unfold {
  0% {
    transform: perspective(800px) rotateY(90deg) scale(0.5);
    opacity: 0;
  }
  50% {
    transform: perspective(800px) rotateY(45deg) scale(0.75);
    opacity: 0.5;
  }
  100% {
    transform: perspective(800px) rotateY(0deg) scale(1);
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.25); }
}

@keyframes slideUp {
  from { transform: translateY(15px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-15px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes shimmer {
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(6, 100, 105, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(6, 100, 105, 0); }
  100% { box-shadow: 0 0 0 0 rgba(6, 100, 105, 0); }
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0px); }
}

// Intro Animation
.intro-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, $background-color 0%, #f0f9fa 100%);
  @include flex-center;
  z-index: 9999;
  animation: fadeOut 0.5s ease-out 0.8s forwards; // Reduced from 2.5s to 0.8s
}

.intro-logo {
  width: 220px;
  height: auto;
  animation: zoomIn 2s $transition-bounce;
  filter: drop-shadow(0 10px 15px rgba(6, 100, 105, 0.2));
  font-family: $font-bodoni;
}

// Navbar Styles
.navbar {
  background-color: rgba(255, 255, 255, 0.98);
  border-bottom: 1px solid rgba(6, 100, 105, 0.08);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: 0;
  font-family: $font-bodoni;
  transition: all $transition-duration $transition-ease;
  
  @include mobile {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.97), rgba(240, 249, 250, 0.97));
    border-bottom: none;
    box-shadow: 0 2px 15px rgba(6, 100, 105, 0.08);
  }
  
  &.hidden {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
  
  &--scrolled {
    @include glass-effect;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
    border-bottom: none;
    
    .navbar__container {
      height: 65px;
      
      @include mobile {
        height: 55px;
      }
      
      @include small-mobile {
        height: 50px;
      }
    }
    
    .navbar__logo-img {
      transform: scale(0.95);
      
      @include mobile {
        transform: scale(0.9);
      }
    }
  }

  &__container {
    max-width: $desktop;
    margin: 0 auto;
    padding: 0 2rem;
    @include flex-between;
    height: 85px;
    position: relative;
    width: 90%;
    transition: all $transition-duration $transition-ease;

    @include mobile {
      padding: 0 1.25rem;
      height: 65px;
      width: 95%;
      padding: 0 0.75rem 0 1rem;
    }
    
    @include small-mobile {
      height: 60px;
      width: 100%;
      padding: 0 0.5rem 0 0.75rem;
    }
  }
  
  &__left {
    display: flex;
    align-items: center;
  }

  .animated-banner {
    position: sticky;
    top: 0;
    z-index: 1001;
    width: 100%;
  }

  &__logo {
    margin-left: 1.25rem;
    font-family: $font-bodoni;
    position: relative;

    @include mobile {
      margin: 0;
      display: flex;
      align-items: center;
    }

    &-img {
      height: 55px;
      width: auto;
      transition: all $transition-duration $transition-bounce;
      filter: drop-shadow(0 3px 6px rgba(6, 100, 105, 0.15));

      @include mobile {
        height: 42px;
        filter: drop-shadow(0 3px 8px rgba(6, 100, 105, 0.2));
      }
      
      @include small-mobile {
        height: 38px;
      }
      
      &:hover {
        transform: scale(1.05);
        filter: drop-shadow(0 5px 10px rgba(6, 100, 105, 0.2));
      }
    }

    &.animated-logo {
      animation: unfold 1.5s $transition-bounce;
    }
    
    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 2px;
      background: linear-gradient(90deg, transparent, $primary-color, transparent);
      transition: width 0.3s ease;
      opacity: 0;
      border-radius: 2px;
    }
    
    &:hover::after {
      width: 80%;
      opacity: 1;
    }
  }

  &__sidebar-toggle {
    border: none;
    background: transparent;
    padding: 0.75rem;
    margin-right: 0.5rem;
    cursor: pointer;
    border-radius: 12px;
    @include flex-center;
    transition: all $transition-quick $transition-bounce;
    
    @include mobile {
      padding: 0.5rem;
      margin-right: 0.35rem;
      background-color: rgba(255, 255, 255, 0.85);
      box-shadow: 0 2px 10px rgba(6, 100, 105, 0.1);
      border-radius: 12px;
    }
    
    @include small-mobile {
      padding: 0.4rem;
      margin-right: 0.25rem;
    }

    &:hover {
      background-color: $hover-color;
      transform: scale(1.05);
    }

    .icon-container {
      @include flex-center;
      overflow: hidden;

      svg {
        width: 24px;
        height: 24px;
        color: $primary-color !important;
        transition: all $transition-quick $transition-bounce;

        &.rotating-enter {
          animation: rotateIn 0.3s $transition-bounce;
        }

        &.rotating-exit {
          animation: rotateOut 0.3s $transition-bounce;
        }
      }
    }

    &:hover .icon-container svg {
      color: darken($primary-color, 10%) !important;
      transform: scale(1.1);
    }
    
    &:active {
      transform: scale(0.95);
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 0 1rem;

    @include mobile {
      display: none;
      
      &--mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.85rem;
        margin-top: 2rem;
        width: 100%;
        padding: 0 1.25rem;
        position: relative;
        
        &::before {
          content: '';
          position: absolute;
          left: 0.75rem;
          top: -15px;
          width: 50px;
          height: 3px;
          background: linear-gradient(to right, $primary-color, transparent);
          border-radius: 3px;
        }
        
        &::after {
          content: '';
          position: absolute;
          right: 1.5rem;
          bottom: -25px;
          width: 70px;
          height: 3px;
          background: linear-gradient(to left, $accent-color, transparent);
          border-radius: 3px;
        }
      }
    }
    
    @include small-mobile {
      &--mobile {
        padding: 0 0.75rem;
      }
    }
  }

  &__link {
    color: $text-color;
    text-decoration: none;
    padding: 0.5rem 0.25rem;
    transition: all $transition-duration $transition-bounce;
    font-weight: 500;
    font-size: 1rem;
    font-family: $font-bodoni;
    position: relative;
    letter-spacing: 0.5px;

    &:hover {
      color: $primary-color;
      transform: translateY(-2px);
    }
    
    &--active {
      color: $primary-color;
      font-weight: 600;
    }
    
    &-indicator {
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      height: 2px;
      background: linear-gradient(90deg, transparent, $primary-color, transparent);
      border-radius: 4px;
      transition: all 0.3s $transition-bounce;
    }
    
    @include mobile {
      width: 100%;
      padding: 0.95rem 1.25rem;
      font-size: 1.1rem;
      border-radius: 16px;
      letter-spacing: 0.8px;
      margin: 0.2rem 0;
      background-color: rgba(255, 255, 255, 0.6);
      box-shadow: 0 2px 8px rgba(6, 100, 105, 0.05);
      backdrop-filter: blur(4px);
      
      &:hover {
        transform: translateX(5px);
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 4px 15px rgba(6, 100, 105, 0.1);
      }
      
      &--active {
        background: linear-gradient(to right, rgba(6, 100, 105, 0.08), rgba(255, 255, 255, 0.8));
        border-left: 4px solid $primary-color;
        font-weight: 700;
        box-shadow: 0 4px 12px rgba(6, 100, 105, 0.1);
      }
    }
    
    @include small-mobile {
      padding: 0.85rem 1rem;
      font-size: 1rem;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 0.85rem;

    @include mobile {
      gap: 0.4rem;
    }
  }

  &__action-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    color: $primary-color;
    transition: all $transition-duration $transition-bounce;
    position: relative;
    @include flex-center;
    border-radius: 50%;
    width: 42px;
    height: 42px;

    @include mobile {
      padding: 0.35rem;
      width: 38px;
      height: 38px;
      background-color: rgba(255, 255, 255, 0.85);
      box-shadow: 0 2px 10px rgba(6, 100, 105, 0.1);
      margin: 0 0.15rem;
    }
    
    @include small-mobile {
      width: 36px;
      height: 36px;
      margin: 0 0.1rem;
    }

    &:hover {
      color: darken($primary-color, 10%);
      background-color: $hover-color;
      transform: scale(1.08);
      box-shadow: 0 4px 12px rgba(6, 100, 105, 0.15);
    }
    
    &:active {
      transform: scale(0.95);
    }

    .navbar__icon {
      width: 22px;
      height: 22px;
      fill: currentColor;

      @include mobile {
        width: 20px;
        height: 20px;
      }
      
      @include small-mobile {
        width: 18px;
        height: 18px;
      }
    }
  }
  
  &__cart-btn {
    background: linear-gradient(135deg, $primary-color, $secondary-color);
    color: white;
    box-shadow: 0 4px 12px rgba(6, 100, 105, 0.2);
    
    @include mobile {
      background: linear-gradient(135deg, $primary-color, $secondary-color);
      color: white;
      box-shadow: 0 4px 15px rgba(6, 100, 105, 0.25);
      width: 42px;
      height: 42px;
      margin-left: 0.25rem;
    }
    
    @include small-mobile {
      width: 38px;
      height: 38px;
    }
    
    &:hover {
      background: linear-gradient(135deg, darken($primary-color, 5%), darken($secondary-color, 5%));
      color: white;
      box-shadow: 0 6px 16px rgba(6, 100, 105, 0.25);
    }
    
    &:active {
      box-shadow: 0 2px 8px rgba(6, 100, 105, 0.15);
    }
  }

  &__user-container {
    position: relative;
  }
  
  &__user-menu {
    position: absolute;
    top: calc(100% + 12px);
    right: 0;
    width: 280px;
    background-color: white;
    border-radius: 16px;
    @include card-shadow;
    overflow: hidden;
    z-index: 1010;
    animation: slideUp 0.3s $transition-bounce;
    @include subtle-border;
    
    @include mobile {
      width: 300px;
      background: linear-gradient(to bottom right, white, #f8fcfc);
      border-radius: 20px;
      top: calc(100% + 15px);
      right: -10px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    }
    
    @include small-mobile {
      width: 260px;
      right: -5px;
    }
    
    &-content {
      padding: 1.25rem;
    }
    
    &-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding-bottom: 1rem;
    }
    
    &-divider {
      height: 1px;
      background: linear-gradient(90deg, transparent, $medium-gray, transparent);
      margin: 0.75rem 0;
    }
    
    &-options {
      display: flex;
      flex-direction: column;
      gap: 0.35rem;
      margin-top: 0.75rem;
    }
    
    &-item {
      display: flex;
      align-items: center;
      gap: 0.85rem;
      width: 100%;
      text-align: left;
      padding: 0.85rem;
      border: none;
      background: none;
      border-radius: 10px;
      cursor: pointer;
      transition: all $transition-quick $transition-bounce;
      color: $text-color;
      font-size: 0.95rem;
      font-family: $font-sans;
      
      &:hover {
        background-color: $hover-color;
        color: $primary-color;
        transform: translateX(4px);
      }
      
      &--logout {
        color: $error-color;
        margin-top: 0.5rem;
        
        &:hover {
          background-color: rgba($error-color, 0.07);
          color: $error-color;
        }
      }
    }
    
    &-icon {
      width: 18px;
      height: 18px;
      fill: currentColor;
    }
  }
  
  &__user-avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: linear-gradient(135deg, $primary-color, $secondary-color);
    color: white;
    @include flex-center;
    font-weight: 600;
    font-size: 18px;
    box-shadow: 0 4px 10px rgba(6, 100, 105, 0.2);
    border: 2px solid white;
  }
  
  &__user-info {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  
  &__user-mobile {
    font-weight: 600;
    color: $text-color;
    font-size: 0.95rem;
    letter-spacing: 0.3px;
  }
  
  &__user-email {
    font-size: 0.85rem;
    color: $dark-gray;
    letter-spacing: 0.2px;
  }
}

// Cart count badge
.cart-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background: linear-gradient(135deg, $accent-color, $accent-secondary);
  color: $primary-dark;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  @include flex-center;
  font-size: 0.75rem;
  font-weight: 700;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  font-family: $font-sans;
  border: 2px solid white;
  animation: pulse 2s infinite;
  
  @include mobile {
    width: 24px;
    height: 24px;
    top: -6px;
    right: -6px;
    font-size: 0.8rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &.animate {
    animation: bounce 0.4s $transition-bounce;
  }
}

// Search functionality
.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  @include flex-center;
  z-index: 1500;
  padding: 1.5rem;
  animation: fadeIn 0.3s ease;
  
  @include mobile {
    padding: 1rem;
    align-items: flex-start;
    padding-top: 15%;
  }
  
  @include small-mobile {
    padding: 0.75rem;
    padding-top: 20%;
  }
}

.search-container {
  width: 92%;
  max-width: 750px;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  @include card-shadow;
  animation: slideDown 0.4s $transition-bounce;
  
  @include mobile {
    width: 100%;
    max-height: 70vh;
    border-radius: 25px;
    background: linear-gradient(to bottom, white, #f8fcfc);
  }
  
  @include small-mobile {
    max-height: 65vh;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(6, 100, 105, 0.02), rgba(255, 255, 255, 0));
    pointer-events: none;
  }
}

.search-box {
  display: flex;
  align-items: center;
  padding: 1.25rem;
  border-bottom: 1px solid $medium-gray;
  background-color: $light-gray;
  
  @include mobile {
    padding: 1.5rem;
    border-bottom: none;
    box-shadow: 0 4px 15px rgba(6, 100, 105, 0.05);
    background: linear-gradient(to right, $light-gray, white);
  }
  
  .search-icon {
    color: $primary-color;
    margin-right: 1rem;
    width: 22px;
    height: 22px;
  }
  
  .search-input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 1.1rem;
    font-family: $font-sans;
    color: $text-color;
    background: transparent;
    
    &::placeholder {
      color: $dark-gray;
      font-style: italic;
      opacity: 0.8;
    }
    
    &:focus::placeholder {
      opacity: 0.5;
    }
  }
  
  .search-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    color: $dark-gray;
    border-radius: 50%;
    @include flex-center;
    transition: all $transition-quick $transition-bounce;
    width: 36px;
    height: 36px;
    
    &:hover {
      background-color: rgba(6, 100, 105, 0.1);
      color: $primary-color;
      transform: rotate(90deg) scale(1.1);
    }
  }
}

.search-results {
  padding: 1.25rem;
  overflow-y: auto;
  
  &-header {
    @include flex-between;
    margin-bottom: 1.25rem;
    
    h3 {
      font-family: $font-bodoni;
      font-size: 1.1rem;
      color: $text-color;
      margin: 0;
      position: relative;
      display: inline-block;
      
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        height: 2px;
        width: 40%;
        background: linear-gradient(90deg, $primary-color, transparent);
        border-radius: 2px;
      }
    }
    
    a {
      font-size: 0.9rem;
      color: $primary-color;
      text-decoration: none;
      padding: 0.4rem 0.8rem;
      border-radius: 20px;
      background-color: $hover-color;
      transition: all $transition-duration $transition-bounce;
      
      &:hover {
        background-color: $primary-light;
        color: white;
        transform: translateY(-2px);
        box-shadow: 0 4px 10px rgba(6, 100, 105, 0.15);
      }
    }
  }
  
  &-empty {
    padding: 3rem 0;
    text-align: center;
    color: $dark-gray;
    font-family: $font-sans;
    font-size: 0.95rem;
    font-style: italic;
    
    &::before {
      content: '✨';
      display: block;
      font-size: 2rem;
      margin-bottom: 0.5rem;
      animation: float 3s ease-in-out infinite;
    }
  }
}

// Icons
.navbar__icon {
  width: 22px;
  height: 22px;
  fill: currentColor;
  transition: all $transition-duration $transition-bounce;

  @include mobile {
    width: 20px;
    height: 20px;
  }
}

.navbar__user-icon-svg,
.navbar__cart-icon-svg {
  @extend .navbar__icon;
}

.MuiSvgIcon-root {
  color: $primary-color !important;
  transition: all $transition-duration $transition-bounce !important;
  
  &:hover {
    color: darken($primary-color, 10%) !important;
    transform: scale(1.1);
  }
}

// Custom Scrollbar
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: $light-gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(6, 100, 105, 0.3);
  border-radius: 10px;
  
  &:hover {
    background: rgba(6, 100, 105, 0.5);
  }
}

// Additional Hover Effects
.navbar__logo-img,
.navbar__link,
.navbar__action-btn,
.cart-count {
  will-change: transform;
}

// Mobile-specific styles
@include mobile {
  // Bottom navigation for mobile
  .mobile-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 65px;
    background-color: white;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0 10px;
    z-index: 999;
    @include glass-effect;
    
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 12px;
      border-radius: 12px;
      
      &:active {
        background-color: $hover-color;
      }
      
      &-icon {
        color: $dark-gray;
        margin-bottom: 4px;
        font-size: 20px;
      }
      
      &-label {
        font-size: 0.7rem;
        color: $dark-gray;
      }
      
      &--active {
        .mobile-bottom-nav__item-icon,
        .mobile-bottom-nav__item-label {
          color: $primary-color;
        }
      }
    }
    
    &__center-button {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: linear-gradient(135deg, $primary-color, $secondary-color);
      @include flex-center;
      margin-top: -25px;
      box-shadow: 0 5px 15px rgba(6, 100, 105, 0.25);
      border: 4px solid white;
      color: white;
      position: relative;
      z-index: 2;
      
      &::before {
        content: '';
        position: absolute;
        top: -8px;
        left: -8px;
        right: -8px;
        bottom: -8px;
        border-radius: 50%;
        background: transparent;
        border: 2px solid rgba(255, 255, 255, 0.2);
        animation: pulse 2s infinite;
      }
    }
  }
  
  // Mobile sidebar enhancements
  .mobile-sidebar {
    background: linear-gradient(135deg, #f8fcfc, white);
    border-radius: 0 20px 20px 0;
    padding: 2rem 1rem;
    width: 85%;
    max-width: 320px;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    @include card-shadow;
    transform: translateX(-100%);
    transition: all 0.4s $transition-bounce;
    
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
      padding: 0 1rem;
      
      &-logo {
        height: 40px;
        width: auto;
      }
      
      &-close {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $hover-color;
        border: none;
        @include flex-center;
        cursor: pointer;
        color: $primary-color;
        transition: all 0.3s ease;
        
        &:hover {
          background-color: rgba($primary-color, 0.1);
          transform: rotate(90deg);
        }
      }
    }
    
    &__divider {
      height: 1px;
      background: linear-gradient(to right, $medium-gray, transparent);
      margin: 1.5rem 0;
    }
    
    &__nav {
      margin-bottom: 2rem;
    }
    
    &__item {
      display: flex;
      align-items: center;
      padding: 1rem;
      color: $text-color;
      text-decoration: none;
      border-radius: 12px;
      margin-bottom: 0.5rem;
      transition: all 0.3s ease;
      
      &-icon {
        margin-right: 1rem;
        width: 24px;
        height: 24px;
        color: $primary-color;
      }
      
      &-text {
        font-weight: 500;
      }
      
      &:hover, &--active {
        background-color: $hover-color;
        transform: translateX(5px);
      }
      
      &--active {
        color: $primary-color;
        font-weight: 600;
      }
    }
    
    &__footer {
      padding: 1rem;
      text-align: center;
      color: $dark-gray;
      font-size: 0.85rem;
      margin-top: auto;
      
      &-logo {
        width: 80px;
        margin-bottom: 1rem;
        opacity: 0.7;
      }
    }
    
    &.active {
      transform: translateX(0);
    }
  }
  
  .mobile-sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    z-index: 1100;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  
  // Mobile search improvements
  .search-box {
    @include mobile {
      .search-input {
        font-size: 1.1rem;
        padding: 0.5rem 0;
        
        &::placeholder {
          font-size: 1rem;
        }
      }
      
      .search-icon {
        width: 24px;
        height: 24px;
      }
      
      .search-close-btn {
        width: 38px;
        height: 38px;
        background-color: rgba($primary-color, 0.05);
        margin-left: 0.5rem;
      }
    }
  }
  
  // Mobile dropdown style enhancements
  .mobile-dropdown {
    background-color: white;
    border-radius: 15px;
    margin-top: 10px;
    padding: 1rem;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.3s ease;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: -8px;
      left: 20px;
      width: 16px;
      height: 16px;
      background-color: white;
      transform: rotate(45deg);
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      border-left: 1px solid rgba(0, 0, 0, 0.05);
    }
    
    &__item {
      padding: 0.75rem;
      display: flex;
      align-items: center;
      border-radius: 10px;
      transition: all 0.2s ease;
      
      &-icon {
        margin-right: 0.8rem;
        color: $primary-color;
      }
      
      &-text {
        font-weight: 500;
        color: $text-color;
      }
      
      &:hover {
        background-color: $hover-color;
      }
    }
  }
}

// Small mobile specific styles
@include small-mobile {
  .intro-logo {
    width: 180px;
  }
  
  .mobile-sidebar {
    width: 90%;
    padding: 1.5rem 0.75rem;
  }
  
  .mobile-bottom-nav {
    height: 60px;
    
    &__center-button {
      width: 50px;
      height: 50px;
      margin-top: -20px;
    }
    
    &__item {
      padding: 6px 8px;
      
      &-icon {
        font-size: 18px;
      }
      
      &-label {
        font-size: 0.65rem;
      }
    }
  }
  
  .search-box {
    .search-input {
      font-size: 1rem;
    }
    
    .search-icon {
      width: 20px;
      height: 20px;
    }
    
    .search-close-btn {
      width: 34px;
      height: 34px;
    }
  }
}

// Media query refinements
@media (min-width: $desktop) {
  .navbar__container {
    max-width: 1300px;
  }
  
  .navbar__link {
    font-size: 1.05rem;
  }
}

// Add additional keyframes for mobile animations
@keyframes mobileSlideIn {
  from { 
    transform: translateX(-100%);
    opacity: 0;
  }
  to { 
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes mobileFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

// Mobile gestures support
.mobile-gesture-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  z-index: 990;
}

// Mobile notification badge
.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $accent-color;
  color: $primary-dark;
  font-size: 0.65rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

// Mobile theme toggle
.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: $light-gray;
  border-radius: 12px;
  margin: 1rem 0;
  
  &__label {
    font-weight: 500;
    color: $text-color;
  }
  
  &__switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
    
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $medium-gray;
      transition: 0.4s;
      border-radius: 34px;
      
      &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
    
    input:checked + .slider {
      background-color: $primary-color;
    }
    
    input:checked + .slider:before {
      transform: translateX(24px);
    }
  }
}

// Mobile cart enhancements
.mobile-cart-indicator {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(135deg, $primary-color, $secondary-color);
  color: white;
  @include flex-center;
  box-shadow: 0 8px 20px rgba(6, 100, 105, 0.25);
  cursor: pointer;
  z-index: 980;
  
  &__icon {
    font-size: 24px;
  }
  
  &__count {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $accent-color;
    color: $primary-dark;
    font-size: 0.8rem;
    font-weight: 700;
    @include flex-center;
    border: 2px solid white;
  }
  
  &:active {
    transform: scale(0.95);
  }
}