.admin-products {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
  
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #333;
      }
    }
  }
  
  .alert {
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  
    &-error {
      background-color: #fee2e2;
      border: 1px solid #ef4444;
      color: #991b1b;
    }
  
    &-success {
      background-color: #dcfce7;
      border: 1px solid #22c55e;
      color: #166534;
    }
  }
  
  .product-form {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
    .form-group {
      margin-bottom: 1.5rem;
  
      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: #374151;
      }
  
      input, select, textarea {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #d1d5db;
        border-radius: 4px;
        background-color: #fff;
        transition: border-color 0.2s;
  
        &:focus {
          outline: none;
          border-color: #066469;
          box-shadow: 0 0 0 2px rgba(6, 100, 105, 0.1);
        }
      }
  
      textarea {
        min-height: 100px;
        resize: vertical;
      }
    }
  
    .form-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  
    .variation-section {
      background: #f9fafb;
      padding: 1.5rem;
      border-radius: 6px;
      margin: 1.5rem 0;
  
      h3 {
        margin-bottom: 1.5rem;
        font-size: 1.25rem;
        color: #374151;
      }
    }
  
    .file-input-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem;
      border: 2px dashed #d1d5db;
      border-radius: 4px;
      cursor: pointer;
      transition: border-color 0.2s;
  
      &:hover {
        border-color: #066469;
      }
  
      input[type="file"] {
        opacity: 0;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
      }

      svg {
        color: #066469;
      }
    }

    .image-preview {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      gap: 1rem;
      margin-top: 1rem;

      &-item {
        position: relative;
        aspect-ratio: 1;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid #d1d5db;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  
  .products-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  
    .product-card {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      padding: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: start;
      transition: transform 0.2s, box-shadow 0.2s;
  
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }
  
      &__content {
        h3 {
          font-size: 1.125rem;
          font-weight: 600;
          color: #1f2937;
          margin-bottom: 0.5rem;
        }
  
        p {
          color: #6b7280;
          margin-bottom: 0.25rem;
  
          &.price {
            color: #066469;
            font-weight: 600;
            font-size: 1.125rem;
          }
        }
      }
  
      &__actions {
        display: flex;
        gap: 0.5rem;
      }
    }
  }
  
  .btn {
    &-primary {
      background-color: #066469;
      color: #fff;
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      border: none;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
  
      &:hover {
        background-color: darken(#066469, 5%);
      }
    }
  
    &-secondary {
      background-color: #f3f4f6;
      color: #374151;
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      border: 1px solid #d1d5db;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
  
      &:hover {
        background-color: #e5e7eb;
      }
    }
  
    &-icon {
      background: none;
      border: none;
      padding: 0.5rem;
      border-radius: 4px;
      cursor: pointer;
      color: #066469;
      transition: background-color 0.2s;
  
      &:hover {
        background-color: rgba(6, 100, 105, 0.1);
      }
  
      &--danger {
        color: #ef4444;
  
        &:hover {
          background-color: rgba(239, 68, 68, 0.1);
        }
      }
    }
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
  }
  
  // Media Queries
  @media (max-width: 768px) {
    .admin-products {
      padding: 1rem;
  
      &__header {
        flex-direction: column;
        gap: 1rem;
        align-items: stretch;
        
        button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  
    .form-row {
      grid-template-columns: 1fr;
    }
  
    .products-list {
      grid-template-columns: 1fr;
    }
  }
  
  // Animation
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .product-form, .product-card {
    animation: fadeIn 0.3s ease-out;
  }