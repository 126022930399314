.admin-contact-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  
    .admin-contact-header {
      margin-bottom: 2rem;
  
      h1 {
        font-size: 2rem;
        color: #333;
        margin-bottom: 1rem;
      }
  
      .stats {
        display: flex;
        gap: 2rem;
        background: #f8f9fa;
        padding: 1rem;
        border-radius: 8px;
  
        .stat-item {
          display: flex;
          gap: 0.5rem;
  
          span:first-child {
            color: #666;
          }
  
          span:last-child {
            font-weight: bold;
            color: #066469;
          }
        }
      }
    }
  
    .contact-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      .contact-card {
        background: white;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 1.5rem;
        display: flex;
        justify-content: space-between;
        gap: 2rem;
  
        &.pending {
          border-left: 4px solid #f59e0b;
        }
  
        &.responded {
          border-left: 4px solid #10b981;
        }
  
        &.closed {
          border-left: 4px solid #6b7280;
        }
  
        .contact-info {
          flex: 1;
  
          h3 {
            font-size: 1.2rem;
            color: #333;
            margin-bottom: 0.5rem;
          }
  
          .message {
            color: #4b5563;
            margin-bottom: 1rem;
            line-height: 1.5;
          }
  
          .sender-details {
            font-size: 0.9rem;
            color: #666;
  
            p {
              margin: 0.25rem 0;
            }
          }
        }
  
        .contact-actions {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          min-width: 150px;
  
          .status-select {
            padding: 0.5rem;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            background: white;
            cursor: pointer;
  
            &.pending {
              color: #f59e0b;
            }
  
            &.responded {
              color: #10b981;
            }
  
            &.closed {
              color: #6b7280;
            }
          }
  
          .reply-button {
            text-decoration: none;
            background: #066469;
            color: white;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            text-align: center;
            transition: background-color 0.2s;
  
            &:hover {
              background: darken(#066469, 10%);
            }
          }
        }
      }
    }
  }
  
  .admin-contact-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    gap: 1rem;
  
    .spinner {
      animation: spin 1s linear infinite;
    }
  }
  
  .admin-contact-error {
    text-align: center;
    padding: 2rem;
    color: #dc2626;
  
    button {
      margin-top: 1rem;
      padding: 0.5rem 1rem;
      background: #066469;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  
      &:hover {
        background: darken(#066469, 10%);
      }
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }