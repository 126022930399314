// Import luxury fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

// CustomerAttractionStrip.scss
.professional-attraction-strip {
  --primary-color: #022e37;
  --secondary-color: #09a3ad;
  --accent-color: #F8E16C;
  --text-color: #ffffff;
  --strip-height: 64px;
  
  position: relative;
  height: var(--strip-height);
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  
  // Premium gradient background with animation
  background: linear-gradient(
    135deg, 
    var(--primary-color) 0%, 
    var(--secondary-color) 50%, 
    var(--primary-color) 100%
  );
  background-size: 300% 300%;
  animation: gradientAnimation 15s ease infinite;
  
  // Professional shadow
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25), inset 0 1px 0 rgba(255, 255, 255, 0.15);
  
  // Subtle border effect
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  
  // Particle effects for background
  .particles-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
  }
  
  .particle {
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    
    @for $i from 0 through 9 {
      &.particle-#{$i} {
        $size: random(6) + 2px;
        $delay: random(15) * 1s;
        $duration: (random(10) + 15) * 1s;
        $start-position: random(100) * 1%;
        
        width: $size;
        height: $size;
        left: $start-position;
        animation: floatParticle $duration $delay infinite linear;
        animation-delay: $delay;
      }
    }
  }
  
  // Shimmer effect
  .shimmer-effect {
    position: absolute;
    top: 0;
    left: -150%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: skewX(-20deg);
    animation: shimmerEffect 8s infinite;
    pointer-events: none;
  }
  
  // Interactive light effect
  .light-effect {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(
      circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    pointer-events: none;
    z-index: 1;
  }
  
  // Message container
  .message-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  
  // Benefit message
  .benefit-message {
    position: absolute;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    padding: 0 20px;
    transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    
    &.fadeIn {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
    
    &.fadeOut {
      opacity: 0;
      transform: translateY(-20px) scale(0.95);
    }
    
    &.hidden {
      opacity: 0;
      transform: translateY(20px) scale(0.95);
      pointer-events: none;
    }
    
    .benefit-icon {
      margin-right: 12px;
      font-size: 20px;
      display: inline-block;
      animation: pulseIcon 2s infinite;
      filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
    }
    
    .benefit-text {
      white-space: nowrap;
    }
    
    .highlight {
      color: var(--accent-color);
      font-weight: 700;
      position: relative;
      display: inline-block;
      animation: pulseHighlight 2s infinite;
      
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--accent-color);
        opacity: 0.6;
      }
    }
  }
  
  // Edge borders for premium look
  .edge-border {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    
    &.top {
      top: 0;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    }
    
    &.bottom {
      bottom: 0;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.15), transparent);
    }
  }
}

// Animation keyframes
@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes shimmerEffect {
  0% { left: -150%; }
  50%, 100% { left: 150%; }
}

@keyframes floatParticle {
  0% {
    transform: translateY(100%);
    opacity: 0.7;
  }
  100% {
    transform: translateY(-100px) rotate(360deg);
    opacity: 0;
  }
}

@keyframes pulseIcon {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
}

@keyframes pulseHighlight {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.03); }
}

// Responsive adaptations
@media (max-width: 768px) {
  .professional-attraction-strip {
    --strip-height: 58px;
    margin-top: 16px;
    
    .benefit-message {
      font-size: 14px;
      
      .benefit-icon {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 576px) {
  .professional-attraction-strip {
    --strip-height: 52px;
    margin-top: 12px;
    
    .benefit-message {
      font-size: 12px;
      letter-spacing: 0.5px;
      
      .benefit-icon {
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }
}