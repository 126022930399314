@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600;700&family=Montserrat:wght@500;600;700&display=swap');

@keyframes gentle-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.combo-banner-wrapper {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.combo-banner {
  width: 90%;
  max-width: 800px;
  padding: 1.4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  &.hovered {
    .combo-banner__text::after {
      width: 80%;
      opacity: 0.8;
    }
    
    .combo-banner__highlight::before {
      height: 12px;
      bottom: 3px;
      opacity: 0.3;
    }
    
    .combo-banner__badge {
      transform: scale(1.1);
    }
  }
  
  &__text {
    font-size: 3.5rem;
    text-transform: none;
    letter-spacing: 0.05em;
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    color: #333333;
    position: relative;
    white-space: nowrap;
    padding: 0 1.5rem;
    margin: 0 1rem;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 40%;
      height: 1px;
      background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.5), transparent);
      transition: all 0.4s ease;
    }
    
    &::first-letter {
      font-size: 120%;
      color: #d4af37;
    }
  }
  
  &__highlight {
    font-weight: 700;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 8px;
      bottom: 5px;
      left: 0;
      background-color: rgba(184, 134, 11, 0.15);
      z-index: -1;
      transition: all 0.4s ease;
    }
  }
  
  &__badge {
    width: 70px;
    height: 70px;
    min-width: 70px; /* Prevent shrinking */
    border-radius: 50%;
    background: linear-gradient(135deg, #d4af37 0%, #f9f295 50%, #d4af37 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.6);
    transition: all 0.4s ease;
    color: #222;
    position: relative;
    z-index: 10; /* Keep badges above text */
    
    &::after {
      content: '';
      position: absolute;
      width: 90%;
      height: 90%;
      border-radius: 50%;
      border: 1px dashed rgba(0, 0, 0, 0.3);
      animation: gentle-rotate 15s linear infinite;
    }
    
    span {
      display: block;
      
      &:first-child {
        font-size: 1rem;
        font-weight: 700;
      }
      
      &:last-child {
        font-size: 0.8rem;
      }
    }
    
    &--left {
      margin-right: 1.5rem;
    }
    
    &--right {
      margin-left: 1.5rem;
    }
  }
}

@media (max-width: 768px) {
  .combo-banner__text {
    font-size: 2.8rem;
  }
  
  .combo-banner__badge {
    width: 60px;
    height: 60px;
    min-width: 60px;
    
    span:first-child {
      font-size: 0.9rem;
    }
    
    span:last-child {
      font-size: 0.7rem;
    }
  }
}

@media (max-width: 480px) {
  .combo-banner__text {
    font-size: 2rem;
    margin: 0 0.5rem;
    padding: 0 0.5rem;
  }
  
  .combo-banner__badge {
    width: 50px;
    height: 50px;
    min-width: 50px;
    margin: 0 0.8rem;
    
    span:first-child {
      font-size: 0.8rem;
    }
    
    span:last-child {
      font-size: 0.6rem;
    }
  }
}