// Font Import
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Variables
$font-bodoni: 'Libre Bodoni', serif;
$primary-teal: #066469;
$secondary-teal: #0d6366;
$light-teal: #73c8cc;
$dark-gray: #333333;
$light-gray: #f5f5f5;
$border-color: #e5e7eb;
$text-gray: #6b7280;
$header-bg: #f9fafb;

// Mixins
@mixin card-shadow {
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@mixin hover-shadow {
  box-shadow: 
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Main Styles
.orders-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: $font-bodoni;

  @media (max-width: 1024px) {
    padding: 1rem;
  }

  @media (max-width: 640px) {
    padding: 0.5rem;
  }

  // Dashboard Header
  .dashboard-header {
    position: sticky;
    top: 0;
    background: linear-gradient(to bottom, #fff 0%, rgba(255,255,255,0.95) 100%);
    backdrop-filter: blur(8px);
    padding: 1rem 0 2rem;
    margin-bottom: 2rem;
    z-index: 10;

    @media (max-width: 640px) {
      padding: 0.75rem 0 1.5rem;
      margin-bottom: 1rem;
    }

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
      }

      .header-title {
        display: flex;
        align-items: center;
        gap: 1rem;

        .header-icon {
          width: 48px;
          height: 48px;
          background: linear-gradient(135deg, $primary-teal 0%, $secondary-teal 100%);
          border-radius: 12px;
          @include flex-center;

          @media (max-width: 640px) {
            width: 40px;
            height: 40px;
          }
          
          svg {
            width: 24px;
            height: 24px;
            stroke: white;
            stroke-width: 2;
            fill: none;
          }
        }

        h1 {
          font-size: 1.875rem;
          font-weight: 700;
          background: linear-gradient(135deg, $primary-teal 0%, #3cb2b8 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          @media (max-width: 640px) {
            font-size: 1.5rem;
          }
        }
      }

      .header-controls {
        display: flex;
        gap: 1rem;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
          gap: 0.75rem;
        }

        .search-container {
          position: relative;

          @media (max-width: 768px) {
            width: 100%;
          }

          .search-input {
            width: 300px;
            padding: 0.75rem 1rem 0.75rem 3rem;
            border: 1px solid $border-color;
            border-radius: 12px;
            font-size: 0.875rem;
            background: $header-bg;
            transition: all 0.2s ease;

            @media (max-width: 768px) {
              width: 100%;
            }

            &:focus {
              outline: none;
              border-color: $primary-teal;
              background: white;
              box-shadow: 0 0 0 4px rgba(6, 100, 105, 0.1);
            }
          }

          .search-icon {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;

            svg {
              width: 18px;
              height: 18px;
              stroke: $text-gray;
              stroke-width: 2;
              fill: none;
            }
          }
        }

        .filter-select {
          padding: 0.75rem 2.5rem 0.75rem 1rem;
          border: 1px solid $border-color;
          border-radius: 12px;
          font-size: 0.875rem;
          background: $header-bg url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%239ca3af'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 1rem center;
          background-size: 1rem;
          cursor: pointer;
          appearance: none;

          @media (max-width: 768px) {
            width: 100%;
          }

          &:focus {
            outline: none;
            border-color: $primary-teal;
            background-color: white;
            box-shadow: 0 0 0 4px rgba(6, 100, 105, 0.1);
          }
        }
      }
    }
  }

  // Review Modal Styles
.review-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.review-modal {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  animation: slideIn 0.3s ease-out;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
              0 10px 10px -5px rgba(0, 0, 0, 0.04);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    
    &:hover {
      background: #666;
    }
  }
}

.image-upload-container {
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .hidden {
    display: none;
  }

  .image-preview-area {
    min-height: 100px;
    border: 2px dashed #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    margin-bottom: 8px;
  }

  .upload-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #f3f4f6;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #e5e7eb;
    }
  }

  .preview-container {
    position: relative;
    max-width: 200px;
    
    .image-preview {
      width: 100%;
      height: auto;
      border-radius: 6px;
    }

    .remove-image {
      position: absolute;
      top: -8px;
      right: -8px;
      background-color: #ef4444;
      color: white;
      border: none;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #dc2626;
      }
    }
  }

  .image-help-text {
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 4px;
  }
}

.review-modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  border-radius: 16px 16px 0 0;
  z-index: 1;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
    margin: 0;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #6b7280;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s;

    &:hover {
      background-color: #f3f4f6;
      color: #111827;
    }
  }
}

.review-modal-content {
  padding: 1.5rem;

  .product-name {
    font-size: 1.125rem;
    font-weight: 500;
    color: #374151;
    margin-bottom: 1.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

.rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;

  p {
    font-size: 1rem;
    color: #4b5563;
    margin: 0;
  }

  .stars {
    display: flex;
    gap: 0.5rem;

    .star {
      cursor: pointer;
      stroke: #d1d5db;
      fill: none;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.1);
      }

      &.active {
        stroke: #fbbf24;
        fill: #fbbf24;
      }
    }
  }

  .rating-text {
    font-size: 0.875rem;
    color: #6b7280;
  }
}

.comment-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
  }

  textarea {
    min-height: 120px;
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    resize: vertical;
    font-size: 0.875rem;
    line-height: 1.5;
    transition: all 0.2s;

    &:focus {
      outline: none;
      border-color: #066469;
      box-shadow: 0 0 0 3px rgba(6, 100, 105, 0.1);
    }

    &::placeholder {
      color: #9ca3af;
    }
  }
}

.error-message {
  padding: 0.75rem;
  background-color: #fee2e2;
  border: 1px solid #fecaca;
  border-radius: 8px;
  color: #991b1b;
  font-size: 0.875rem;
}

.button-container {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;

  button {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.2s;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .cancel-button {
    background-color: #f3f4f6;
    color: #4b5563;
    border: none;

    &:hover:not(:disabled) {
      background-color: #e5e7eb;
    }
  }

  .submit-button {
    background-color: #066469;
    color: white;
    border: none;

    &:hover:not(:disabled) {
      background-color: #0d6366;
    }
  }
}

// Add Review Button in Orders List
.order-actions {
  .review-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    background: #066469;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #0d6366;
      transform: translateY(-1px);
    }

    svg {
      width: 18px;
      height: 18px;
      stroke: currentColor;
    }
  }
}

// Animations
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

  // Orders Grid
  .orders-grid {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 600px), 1fr));

    @media (max-width: 640px) {
      gap: 1rem;
    }

    .order-card {
      background: white;
      border-radius: 16px;
      @include card-shadow;
      overflow: hidden;
      transition: transform 0.2s ease, box-shadow 0.2s ease;

      &:hover {
        transform: translateY(-2px);
        @include hover-shadow;
      }

      // Order Header
      .order-header {
        padding: 1.5rem;
        background: linear-gradient(to right, $header-bg, #f3f4f6);
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 640px) {
          padding: 1rem;
          flex-direction: column;
          gap: 1rem;
        }

        .customer-details {
          display: flex;
          align-items: center;
          gap: 1rem;

          .customer-avatar {
            width: 40px;
            height: 40px;
            background: linear-gradient(135deg, $primary-teal 0%, $secondary-teal 100%);
            border-radius: 50%;
            @include flex-center;
            color: white;
            font-weight: 600;
            font-size: 1.125rem;

            @media (max-width: 640px) {
              width: 36px;
              height: 36px;
              font-size: 1rem;
            }
          }

          .customer-info {
            h3 {
              font-weight: 600;
              color: $dark-gray;
              margin-bottom: 0.25rem;

              @media (max-width: 640px) {
                font-size: 0.9375rem;
              }
            }

            .order-date {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              color: $text-gray;
              font-size: 0.875rem;

              .calendar-icon {
                width: 16px;
                height: 16px;
                stroke: $text-gray;
                stroke-width: 2;
                fill: none;
              }
            }
          }
        }

        .order-meta {
          text-align: right;

          @media (max-width: 640px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
          }

          .status-badge {
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            border-radius: 9999px;
            font-size: 0.875rem;
            font-weight: 500;
            margin-bottom: 0.75rem;

            @media (max-width: 640px) {
              margin-bottom: 0;
              padding: 0.375rem 0.75rem;
              font-size: 0.8125rem;
            }

            .status-icon {
              width: 16px;
              height: 16px;
              stroke-width: 2;
              fill: none;
            }

            &.pending {
              background-color: #fef3c7;
              color: #92400e;
              .status-icon { stroke: #92400e; }
            }

            &.processing {
              background-color: #dbeafe;
              color: #1e40af;
              .status-icon { stroke: $primary-teal; }
            }

            &.delivered {
              background-color: #d1fae5;
              color: #065f46;
              .status-icon { stroke: #065f46; }
            }

            &.cancelled {
              background-color: #fee2e2;
              color: #991b1b;
              .status-icon { stroke: #991b1b; }
            }
          }

          .order-total {
            font-weight: 700;
            font-size: 1.25rem;
            color: $dark-gray;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

            @media (max-width: 640px) {
              font-size: 1.125rem;
            }
          }
        }
      }

      // Order Items
      .order-items {
        padding: 1.5rem;
        background: white;

        @media (max-width: 640px) {
          padding: 1rem 0.75rem;
        }

        .item-row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0.75rem;
          border-radius: 8px;
          transition: background-color 0.2s ease;

          @media (max-width: 640px) {
            padding: 0.5rem;
            flex-direction: column;
            gap: 0.75rem;
          }

          &:hover {
            background-color: $header-bg;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #f3f4f6;
            margin-bottom: 0.5rem;
          }

          .item-info {
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            flex: 1;
            min-width: 0;

            @media (max-width: 640px) {
              gap: 0.75rem;
            }

            .item-image-container {
              width: 60px;
              height: 60px;
              min-width: 60px;
              border-radius: 8px;
              overflow: hidden;
              background-color: $light-gray;
              border: 1px solid $border-color;

              @media (max-width: 640px) {
                width: 50px;
                height: 50px;
                min-width: 50px;
              }

              .item-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.2s ease;

                &:hover {
                  transform: scale(1.05);
                }
              }
            }

            .item-details {
              flex: 1;
              min-width: 0;
              display: flex;
              flex-direction: column;
              gap: 0.25rem;

              .item-name {
                font-size: 0.9375rem;
                font-weight: 500;
                color: $dark-gray;
                word-wrap: break-word;
                overflow-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: 1.3;

                @media (max-width: 640px) {
                  font-size: 0.875rem;
                }
              }

              .item-meta {
                display: flex;
                flex-wrap: wrap;
                gap: 0.75rem;
                font-size: 0.875rem;
                color: $text-gray;

                @media (max-width: 640px) {
                  gap: 0.5rem;
                  font-size: 0.8125rem;
                }

                span {
                  display: inline-flex;
                  align-items: center;
                  white-space: nowrap;

                  &:not(:last-child)::after {
                    content: "•";
                    margin-left: 0.75rem;
                    color: #d1d5db;

                    @media (max-width: 640px) {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          .item-total-price {
            font-weight: 600;
            color: $primary-teal;
            white-space: nowrap;
            font-size: 0.9375rem;
            margin-left: auto;
            padding-left: 0.75rem;

            @media (max-width: 640px) {
              align-self: flex-end;
              font-size: 0.875rem;
              padding-left: 0;
            }
          }
        }
      }

      // Order Footer
      .order-footer {
        padding: 1.5rem;
        background: $header-bg;
        border-top: 1px solid $border-color;

        @media (max-width: 640px) {
          padding: 1rem;
        }

        .order-summary {
          margin-bottom: 1rem;
        
          .summary-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 0;
            
            @media (max-width: 640px) {
              font-size: 0.875rem;
            }
        
            span:first-child {
              color: $text-gray;
            }
        
            .payment-method {
              padding: 0.25rem 0.75rem;
              border-radius: 9999px;
              font-size: 0.875rem;
              font-weight: 500;
              display: inline-flex;
              align-items: center;
              gap: 0.5rem;
        
              &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
              }
        
              &.razorpay {
                background-color: #ebf5ff;
                color: #1e40af;
                
                &::before {
                  background-color: #1e40af;
                }
              }
        
              &.cod {
                background-color: #fff7ed;
                color: #9a3412;
        
                &::before {
                  background-color: #9a3412;
                }
              }
            }
        
            .payment-status {
              padding: 0.25rem 0.75rem;
              border-radius: 9999px;
              font-size: 0.875rem;
              font-weight: 500;
        
              &.paid {
                background-color: #d1fae5;
                color: #065f46;
              }
        
              &.pending {
                background-color: #fef3c7;
                color: #92400e;
              }
        
              &.failed {
                background-color: #fee2e2;
                color: #991b1b;
              }
            }
          }
        }

        .order-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;

          @media (max-width: 640px) {
            flex-direction: column;
            gap: 0.75rem;
          }

          .track-status-btn {
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.75rem 1.5rem;
            background: linear-gradient(135deg, $primary-teal 0%, $secondary-teal 100%);
            color: white;
            border-radius: 12px;
            font-weight: 600;
            border: none;
            cursor: pointer;
            transition: all 0.2s ease;
            box-shadow: 0 2px 4px rgba(6, 100, 105, 0.2);

            @media (max-width: 640px) {
              width: 100%;
              justify-content: center;
              padding: 0.625rem;
              font-size: 0.875rem;
            }

            &:hover {
              transform: translateY(-1px);
              box-shadow: 0 4px 6px rgba(6, 100, 105, 0.3);
            }

            &:active {
              transform: translateY(0);
              box-shadow: 0 1px 2px rgba(6, 100, 105, 0.2);
            }

            .arrow-icon {
              width: 16px;
              height: 16px;
              stroke: currentColor;
              stroke-width: 2;
              fill: none;
            }
          }

          .secondary-actions {
            display: flex;
            gap: 1rem;

            @media (max-width: 640px) {
              width: 100%;
              gap: 0.5rem;
            }

            button {
              display: inline-flex;
              align-items: center;
              gap: 0.5rem;
              padding: 0.75rem 1.5rem;
              border-radius: 12px;
              font-weight: 500;
              transition: all 0.2s ease;
              cursor: pointer;

              @media (max-width: 640px) {
                flex: 1;
                justify-content: center;
                padding: 0.625rem;
                font-size: 0.875rem;
              }

              svg {
                width: 16px;
                height: 16px;
                stroke: currentColor;
                stroke-width: 2;
                fill: none;
              }
            }

            .download-btn {
              background: white;
              color: $text-gray;
              border: 1px solid $border-color;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

              &:hover {
                background: $header-bg;
                border-color: #d1d5db;
              }

              &:active {
                background: #f3f4f6;
              }
            }

            .details-btn {
              background: #f3f4f6;
              color: $dark-gray;
              border: none;

              &:hover {
                background: #e5e7eb;
              }

              &:active {
                background: #d1d5db;
              }
            }
          }
        }
      }
    }
  }

  // Loading, Error, and Empty States
  .loading-state,
  .error-state,
  .no-orders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    text-align: center;
    padding: 2rem;
    background: white;
    border-radius: 16px;
    @include card-shadow;

    @media (max-width: 640px) {
      min-height: 150px;
      padding: 1.5rem;
    }
  }

  .retry-button {
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: $primary-teal;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $secondary-teal;
    }
  }
}

// Animations
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.order-card {
  animation: slideIn 0.3s ease-out;
}

// Custom Scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;

  &:hover {
    background: #a1a1a1;
  }
}

// Print Styles
@media print {
  .orders-dashboard {
    padding: 0;
    
    .dashboard-header,
    .order-actions {
      display: none;
    }

    .order-card {
      break-inside: avoid;
      box-shadow: none;
      border: 1px solid $border-color;
      margin-bottom: 1rem;
    }
  }
}